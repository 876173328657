import * as React from 'react'
import Card from './Card'
import Carousel from '../sliders/Carousel'
import { StaticImage } from 'gatsby-plugin-image'

const BreguetCategoryBtns = () => {
  return (
    <section>
      <h1 className="text-center py-20 px-5">
        EXPLORE MORE GREAT BREGUET STORIES FROM GRAY &amp; SONS
      </h1>
      <Carousel
        slides={{ xs: 1, md: 2, xl: 3 }}
        infiniteLoop={false}
        className={'py-8 mx-12'}
        gap={16}
        scrollStep={3}
      >
        <Card
          className="card"
          name="Breguet Classique"
          url={'/preowned/used-breguet-classique-watches-for-sale'}
        >
          <StaticImage
            src="../../images/preowned/used-breguet-classique-article-btn.png"
            alt="Pre-Owned Certified Used Breguet Classique"
            aria-label="Breguet Classique Page"
          />
        </Card>
        <Card
          className="card"
          name="Breguet Marine"
          url={'/preowned/used-breguet-marine-watches-for-sale'}
        >
          <StaticImage
            src="../../images/preowned/used-breguet-marine-article-btn.png"
            alt="Pre-Owned Certified Used Breguet Marine"
            aria-label="Breguet Marine Page"
          />
        </Card>

        <Card
          className="card"
          name="Breguet"
          url={'/preowned/used-breguet-watches-for-sale'}
        >
          <StaticImage
            src="../../images/preowned/used-breguet-article-btn.png"
            alt="Pre-Owned Certified Used Breguet"
            aria-label="Breguet Page"
          />
        </Card>
        <Card
          className="card"
          name="Breguet Grand Complication"
          url={'/preowned/used-breguet-grand-complication-watches-for-sale'}
        >
          <StaticImage
            src="../../images/preowned/used-breguet-grandcomplication-article-btn.png"
            alt="Pre-Owned Certified Used Breguet Grand Complication"
            aria-label="Breguet Grand Complication Page"
          />
        </Card>
       

      </Carousel>
    </section>
  )
}
export default BreguetCategoryBtns
