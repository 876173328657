import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import BreguetCategoryBtns from '../../components/preowned/BreguetCategoryBtns'

// markup
const BreguetGrandComplication = () => {
  const data = useStaticQuery(
    graphql`
      query queryBreguetGrandComplication {
        products: allStrapiProduct(
          filter: { brand: { eq: "Breguet" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Breguet Grand Complication Watches for Sale'}
      canonical={'/fine-watches/other-watches-brands/breguet/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Breguet Grand Complication watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/breguet/">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-breguet-grand-complication-header.png"
                alt="Pre-Owned Certified Used Breguet Watches Header"
                aria-label="Used Breguet Image Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">Used Breguet Grand Complication Watches</h1>
            <h2>
              FIND PRE-OWNED, USED, VINTAGE, ANTIQUE, HEIRLOOM, ESTATE BREGUET GRAND COMPLICATION
              WATCHES AT GRAY AND SONS
            </h2>
            <h3>
              BUY, SELL, TRADE, CONSIGN AND REPAIR USED AND PRE-OWNED BREGUET WATCHES WITH GRAY AND
              SONS JEWELERS
            </h3>
            <p>
              <br />
              The best place to find hard-to-get pre-owned Breguet Grande Complication watches is
              Gray and Sons Jewelers located in Surfside, Florida. Not only do Gray and Sons
              Jewelers sell used Breguet Grande Complication watches, but we also buy genuine used
              Breguet watches from the public. Those looking to get cash for their Breguet Grande
              Complication can inquire in person or request a free watch quote online at{' '}
              <a href="https://sellusyourjewelry.com">www.sellusyourjewelry.com</a>
              . Get a quote to sell your Breguet Grande Complication outright, or trade in your
              Breguet watch for one we have in stock!
              <br />
              <br />
              If ever anywhere near South Florida, count on Gray and Sons Jewelers to be the
              one-stop-shop for all Breguet Grande Complications needs. For over 42 years, Gray and
              Sons have specialized in buying, selling, trading, and repairing Breguet watches. We
              have a brick-and-mortar store located in the heart of Miami, Fl near cities like Sunny
              Isles, Haulover, Aventura, Hollywood, Fort Lauderdale, Miami Beach, Coconut Grove,
              Coral Gables, Key Biscayne, and many more.
              <br />
              <br />
              Clients outside of Florida can contact us via <a href="/">www.grayandsons.com</a>
              and speak with live representatives, Rich and Viktoria, about all Breguet Grande
              Complication watch inquiries today. Gray and Sons Jewelers is the best and most
              reliable place to buy used pre-owned Breguet Grande Complication watches. Our
              swiss-trained watchmakers assure every Breguet watch in our inventory is certified
              authentic and serviced ready for wear with a 1-year warranty covering the movement.
              <br />
              <br />
              Many watch enthusiasts will agree, that Breguet is the finest Swiss watch manufacturer
              in the industry. Within the first decade of establishing his own watchmaking firm,
              Abraham Louis Breguet was widely known as one of the finest horologists of the time,
              this was in the 1780s. He was receiving commissions from some of the finest members of
              society, one of them being the last French Queen, Marie-Antoinette, who happened to be
              a strong admirer of the timepieces.
              <br />
              <br />
              Queen Marie-Antoinette had a vast collection of timepieces, many of them Breguet
              watches, however, she always welcomed more. Members of Marie-Antoinette’s Guard placed
              a commission to Breguet in 1783 for a unique timepiece that was to be gifted to Queen
              Marie Antoinette. This commission specified the watch was to have every complication
              known at the time and the parts were to be made out of gold rather than brass wherever
              possible. This project was handed to Breguet with no limit on price nor hours of
              labor.
              <br />
              <br />
              The final product of this Breguet Marie-Antoinette watch is the grandfather of the
              Breguet model collection we now call the Breguet Grande Complication. The final piece
              never got to the hands of Abraham Louis Breguet nor Queen Marie-Antoinette. This
              Marie-Antoinette Pocket Watch Grande Complication No. 160 was completed by Breguet’s
              son, Louis-Antoine Breguet after 44 years of its initial request.
              <br />
              <br />
              The final product of Breguet No. 160 Grande Complication consisted of a 63mm 18k
              pocket watch with a perpetual calendar showing the day, date, and month, along with a
              minute repeater, hour indicator, minute indicator, sweep seconds indicator, jump hour,
              and thermometer. All these complications are powered by an automatic self-winding
              platinum oscillating weight or what Breguet calls “perpétuelle”. The details and
              complications on this timepiece inspired Breguet to continue with the line of Grande
              Complication pocket watches and wristwatches. Today Breguet has the Classique Grande
              Complication collection inspired by the Marie-Antoinette by carrying several
              complications on the dial.
              <br />
              <br />
              Popular Pre-Owned Breguet Classique Grande Complication References are: Reference
              5719pt Reference 3357BR/12/986 Reference 3357BA/12/986 Reference 3357BB/12/986
              Reference 7337BR/1E/9V6 Reference 3355PT/00/PA0 Reference 3355PT/00/986. Watch
              collectors often visit Gray and Sons Jewelers to find the most collectible Breguet
              Grande Complication watches. Vintage Breguet Grande Complication is some of the most
              desirable vintage collection watches on the market. We buy, sell, trade, and repair
              collectible Breguet Grande Complication watch models.
              <br />
              <br />
              Some people think buying directly from the Breguet boutique is better than getting a
              pre-owned authentic Breguet Grande Complication however this is not true. Buying a
              certified genuine used Breguet comes with several perks, such as saving money, getting
              a hold of exclusive or discontinued models, or even finding out-of-stock watches. Gray
              and Sons Jewelers has vintage Breguet watches and modern Breguet watches in stock
              available for purchase.
              <br />
              <br />
              Worldwide, Breguet watches are recognized for being one of the top watch brands in the
              luxury market and are constantly in demand. If looking to buy a Breguet watch or sell
              a Breguet watch, you should only trust your timepiece in the hands of the best. Here
              at Gray and Sons Jewelers, we have six Swiss-trained watchmakers with over 150 years
              of combined experience who fully service every single Breguet watch in our inventory.
              <br />
              <br />
              We provide repair services to the public as well as provide a 1-year warranty for all
              Breguet watches sold at Gray and Sons. In-house we offer the following services:
              cleaning, polishing, overhaul and lubrication of the movement, battery changing,
              waterproofing, and much more! Come visit our store to repair my Breguet Grande
              Complication watch. Chat with our experts today <a href="/chat-with-rich">here.</a>
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/breguet/">
                <button>SHOP BREGUET WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK BREGUET WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <BreguetCategoryBtns />

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/drvX30qLcgg'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default BreguetGrandComplication
